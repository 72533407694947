.App {
  text-align: center;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 40vmin;
  pointer-events: none;
  display: block;
  margin: auto;

}

.App-header {
  background-color: #282c34;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-brandname i {
  font-size: 3em;
}

.App-brandname h3 {
  font-style: italic;
  margin: 0;
  font-weight: 600;
}

.App-brandname h1 {
  font-family: 'Courier New', Courier, monospace;
  color: #61dafb;
  margin-top: 0px;
}

.App-footer {
  background: #424855;
  color: whitesmoke;
  padding: 0.5em;
}

.App-footer small {
  font-size: 0.6em;
}

.App-footer a {
  color: #61dafb;
  text-decoration: none;
}

.App-link {
  background: #61dafb;
  padding: 1em;
  border: 1px solid #057897;
  border-radius: 3px;
  color: white;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.App-link:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.App-input {
  padding: 1em;
  border-radius: 6px;
  border: 2px solid #002d87;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}

.App-input--sm {
  padding: 0.3em !important; 
}

.correct-answer {
    /*background: linear-gradient(120deg, rgb(49, 205, 97) 30%, rgb(94, 209, 10) 70%);*/
    color: rgb(49, 205, 97);
}

.wrong-answer {
  /*background: linear-gradient(120deg, rgb(255, 37, 99) 30%, rgb(255, 80, 7) 70%);*/
    color: rgb(255, 37, 99)
}

.answer {
    font-size: 5em;
    /* padding: 1em 2em; */
    border-radius: 100px;
    animation-name: answerModal;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.show-up {
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes show {
  from {
    opacity: 0.85;
  }
  to {
    opacity: 1;
  }
}



@keyframes answerModal {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
